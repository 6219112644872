import React from 'react'
import { Link } from 'gatsby'
import { Container, Grid, Typography, Box } from '@material-ui/core'
import qrCodeEmec from '../images/qrcode_15133.png'

import loadable from '@loadable/component'

import '../styles/owl-style.css'
import Banner from '../components/BannerCarousel'
import Comment from '../components/Comments'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SingleLineGridList from '../components/LineGridList'
import SlideOverlay from '../components/SlideOverlay'
import QrCodeImage from '../components/QrCodeImage/'
import * as $ from 'jquery' // importing this worked like a charm
import 'bootstrap/dist/css/bootstrap.min.css'

import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import {
  SectionTitle,
  CourseWrapper,
} from '../styles/pages/home'

import presencialMaior from '../images/presencial-maior.jpg'
import presencialMenor1 from '../images/presencial-menor-1.jpg'
import presencialMenor2 from '../images/presencial-menor-2.jpg'
import presencialMenor3 from '../images/presencial.jpg'
import ead from '../images/ead.jpg'

// DEPOIMENTOS
import depoimento1 from '../images/depoimentos/Datanara.jpg'
import depoimento2 from '../images/depoimentos/Candida-Brito.jpg'
import depoimento3 from '../images/depoimentos/Glessiane-Almeida.jpg'
import depoimento4 from '../images/depoimentos/Luana-Gomes.jpg'
import depoimento5 from '../images/depoimentos/Darliene-Tenorio.jpg'
import depoimento6 from '../images/depoimentos/Mirianne-Almeida.jpg'
import depoimento7 from '../images/depoimentos/Wilker-Mecenas.jpg'
import depoimento8 from '../images/depoimentos/Laura-Fernandes.jpg'
import depoimento9 from '../images/depoimentos/Joao-Mozart.jpg'
import depoimento10 from '../images/depoimentos/Alexandre-Moreira.jpg'
import depoimento11 from '../images/depoimentos/Zaira-Moura.jpg'
import depoimento12 from '../images/depoimentos/Liliam-Franca.jpg'
import depoimento13 from '../images/depoimentos/depoimento13.png'
import depoimento14 from '../images/depoimentos/depoimento14.png'
import depoimento15 from '../images/depoimentos/depoimento15.png'
import depoimento16 from '../images/depoimentos/depoimento16.png'
import depoimento17 from '../images/depoimentos/depoimento17.png'
import bannerAllLagarto from '../images/jardins_all_lagarto.jpg'
import bannerAllLagartoMobile from '../images/jardins_all_lagarto_mobile.jpg'
import bannerSite1 from '../images/banner_site_1.png'
import bannerSite2 from '../images/banner_site_2.png'
import bannerSite4 from '../images/banner_2.png'
import bannerAstSoc from '../images/banner_ast_soc_desktop.png'
import bannerOuvidoria from '../images/banner_ouvidoria.jpeg'
import bannerOuvidoriaMobile from '../images/banner_ouvidoria_mobile.jpeg'
import bannerCPA2023 from '../images/cpa-2024-banner.jpg'
import bannerCPA2023Mobile from '../images/cpa-2024-banner-mobile.jpg'

import bannerSite1_mobile from '../images/banner_1.png'
import bannerSite2_mobile from '../images/banner_3_mobile.png'
import bannerSite4_mobile from '../images/banner_2_mobile.png'
import bannerAstSoc_mobile from '../images/banner_ast_soc.png'
import bannerPosPsico from '../images/banner_pos_psico.jpg'

import bannerNovo2 from '../images/bannerNovo2.jpg'
import bannerNovo2Mobile from '../images/bannerNovo2Mobile.jpg'
import bannerNovo3 from '../images/bannerNovo3.jpg'
import bannerNovo3Mobile from '../images/bannerNovo3Mobile.jpg'
import bannerCopy from '../images/banner-copy.png'
import bannerCopyMobile from '../images/banner-copy-mobile.png'
import bannerEstrategista from '../images/banner_estrategista.jpeg'
import bannerEstrategistaMobile from '../images/banner_estrategia_mobile.jpeg'
import jardinsLogo from '../images/logo.png'
import jardinsAllLogo from '../images/jardins_all_logo.png'
import { makeStyles } from '@material-ui/core/styles'
const OwlCarousel = loadable(() => import('react-owl-carousel'))

const options = {
  autoplay: true,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 2,
    },
  },
}

const optionsBanner = {
  autoplay: true,
  dotsEach: false,
  dots: false,
  nav: false,
  responsive: {
    200: {
      items: 1,
      nav: true,
      center: true,
    },
    600: {
      items: 1,
      nav: true,
      center: true,
    },
    700: {
      items: 1,
      nav: true,
    },
    1000: {
      items: 1,
      // nav: true,
    },
  },
}

function IndexPage() {

  const useStyles = makeStyles(theme => ({
    qrCodeContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      width: '16vw',
      borderRadius: '12px',
      [theme.breakpoints.down('md')]: {
        width: '88vw',
        marginBottom: '40px',
      },
    },
    qrCodeImage: {
      width: '13vw',
      [theme.breakpoints.down('md')]: {
        width: '55vw',
      },
    },
  }));
  const classes = useStyles();
  // const [open, setOpen] = React.useState(true);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  return (
    <Layout>
      <SEO title="Home" />

      {/* Carrosel START */}
      <section className="section-carousel">
        <Grid
          component="div"
          style={{ width: '100%' }}
        >
          <div className="carousel-desk">
            <OwlCarousel
              loop={true}
              // autoplay
              // autoplaySpeed={500}
              items={1}
              className="owl-theme owl-two"
              // stagePadding={6}
              // margin={15}
              dotsEach={false}
              dotos={false}
              {...optionsBanner}
            >
              <Banner img={bannerPosPsico} />
              <Banner img={bannerAllLagarto} />
              <Banner img={bannerCPA2023} />
              <Banner img={bannerEstrategista} />
              <Banner img={bannerCopy} />
              <Banner img={bannerNovo2} />
              <Banner img={bannerNovo3} />
              <Banner img={bannerOuvidoria} />
              <Banner img={bannerAstSoc} />
              <Banner img={bannerSite1} />
              <Banner img={bannerSite2} />
              <Banner img={bannerSite4} />
            </OwlCarousel>
          </div>

          <div className="carousel-mobile">
            <OwlCarousel
              loop={true}
              autoplay
              autoplaySpeed={2500}
              nav={false}
              items={1}
              className="owl-theme owl-two"
              // stagePadding={6}
              // margin={15}
              dotsEach={false}
              dotos={false}
              {...optionsBanner}
            >
              <Banner img={bannerAllLagartoMobile} />
              <Banner img={bannerCPA2023Mobile} />
              <Banner img={bannerEstrategistaMobile} />
              <Banner img={bannerCopyMobile} />
              <Banner img={bannerNovo2Mobile} />
              <Banner img={bannerNovo3Mobile} />
              <Banner img={bannerOuvidoriaMobile} />
              <Banner img={bannerAstSoc_mobile} />
              <Banner img={bannerSite1_mobile} />
              <Banner img={bannerSite2_mobile} />
              <Banner img={bannerSite4_mobile} />
            </OwlCarousel>
          </div>
        </Grid>
      </section>
      {/* Carousel END */}
      {/* Courses START */}
      <Container class="section-conheca">
        <SectionTitle>
          Conheça <span style={{ color: '#ef4e45' }}>nossos</span> cursos
        </SectionTitle>
        <CourseWrapper style={{ display: 'flex' }}>
          <SlideOverlay image={presencialMaior} alt={'Cursos presenciais'}>
            <Link to="/presencial/graduacao">Presencial</Link>
          </SlideOverlay>
          <Typography>
            <h3>Cursos presenciais</h3>
            <p style={{ marginBottom: 44 }}>
              A modalidade de graduação presencial ocorre na Sede da Faculdade
              em Aracaju, onde as atividades presenciais ocorrem de segunda a
              sexta, no período da noite, e aos sábados durante o dia, agregadas
              a estudos virtuais mediante acesso ao AVA.{' '}
              <Link to="/presencial/graduacao" style={{ color: 'blue' }}>
                ...mais
              </Link>
            </p>
            <SingleLineGridList
              images={[
                { img: presencialMenor1, title: 'Curso presencial' },
                { img: presencialMenor2, title: 'Curso presencial' },
                { img: presencialMenor3, title: 'Curso presencial' },
              ]}
            />
          </Typography>
        </CourseWrapper>
        <CourseWrapper style={{ display: 'flex' }}>
          <Typography>
            <h3>Cursos EaD</h3>
            <p style={{ marginBottom: 4 }}>
              Na modalidade a distância, as atividades virtuais ficam a todo
              tempo disponíveis aos alunos mediante acesso ao AVA.As atividades
              presenciais previstas ocorrem em encontros previamente marcados a
              cada semestre, para realização, inclusive, da avaliação presencial{' '}
              <Link to="/ead/graduacao" style={{ color: 'blue' }}>
                ...mais
              </Link>
            </p>
            <SingleLineGridList
              images={[
                { img: presencialMenor1, title: 'Curso EaD' },
                { img: presencialMenor2, title: 'Curso EaD' },
                { img: presencialMenor3, title: 'Curso EaD' },
              ]}
            />{' '}
          </Typography>
          <SlideOverlay image={ead} alt={'Cursos EaD'}>
            <Link to="/ead/graduacao">EaD</Link>
          </SlideOverlay>
        </CourseWrapper>
      </Container>
      {/* Courses END */}
      {/* <Grid container style={{ margin: '50px 22px -22px', flexDirection: 'column' }}>
        <p><strong>* NAE - Núcleo de apoio ao estudante</strong></p>
        <br></br>
        <p style={{marginTop: '-20px'}}>Contato: <a style={{ color: '#0000EE'}} href="mailto:adenildacouto@facjardins.com.br">adenildacouto@facjardins.com.br</a></p>
      </Grid> */}
      {/* Testimonials START */}
      <Grid
        container
        style={{
          paddingBottom: 50,
          margin: '0 20px',
          paddingRight: 47,
          marginTop: 50,
        }}
      >
        <SectionTitle style={{ marginBottom: '20px', width: '100%' }}>
          Depoimentos
        </SectionTitle>
        <div className="container-fluid">
          <OwlCarousel
            items={1}
            className="owl-theme"
            loop={false}
            stagePadding={40}
            nav
            mergeFit
            margin={40}
            dotsEach={true}
            {...options}
          >
            <Comment
              title="Datânara Rejane Nascimento Santos"
              description="Sou Datânara Rejane Nascimento Santos, aluna da faculdade Jardins, cursando o 6º período do curso de Pedagogia. Para mim a Faculdade Jardins é um lugar que me sinto muito bem, sempre fui bem acolhida e me oportunizou o crescimento pessoal e profissional. Encontrei funcionários, coordenadores e professores maravilhosos que sempre me mostraram o mundo de maneira diferente, pois consegui ser quem eu sou. Antes de escolher a Faculdade Jardins, fiz 3 períodos do curso de Sistema em uma Universidade de Aracaju, mas não me encontrei. Por isto, pensei muito se seria uma boa opção mudar de curso e, principalmente, de instituição de ensino, mas não me arrependi em ter tomado a maior e mais assertiva decisão porque foi na Jardins que cresci como pessoa, passei a ter uma visão empreendedora e de educação e, muito mais, aprendi muito com os conhecimentos adquiridos em sala de aula e não desisti dos meus sonhos. 
Eu sou Faculdade Jardins!!!"
              image={depoimento1}
            />

            <Comment
              title="Profª Candida Brito"
              description="Os profissionais da educação são os responsáveis pela formação das futuras gerações e eu acredito que a transformação para um mundo melhor passa por uma boa educação. A Faculdade Jardins com  foco na qualidade de ensino possui um corpo docente com Mestres e Doutores proporcionando  uma formação que aborda o que há de mais moderno no ensino, teorias, didática, em tecnologias  na educação e inovação, formando profissionais comprometidos com uma educação de qualidade."
              image={depoimento2}
            />
            <Comment
              title="Profª Glessiane Almeida "
              description="Com a “Era do Conhecimento”, a sociedade vem experienciando inúmeras transformações na educação, saúde, inovação, tecnologia, entre outras. Perante o exposto, a Faculdade Jardins tem atuado com maestria na busca incessante de transformar a realidade do nosso Estado, alinhando em suas atividades, o conhecimento e a prática, com sua estratégia de desenvolver pessoas de modo eficaz, inteligível e objetiva. A equipe docente, sempre atualizada, procura proposição em apresentar novos modelos de aprendizagem, como cases de sucesso, trilhas educacionais, gameficação, etc., para o sucesso e engajamento dos nossos acadêmicos. Como parte do corpo docente, busco inovar na democratização da educação, além de proporcionar o desenvolvimento de novas competências e habilidades, estimulando a formação de profissionais preparados para o mercado de trabalho."
              image={depoimento3}
            />
            <Comment
              title="Luana Gomes"
              description="Tenho muito orgulho de fazer parte dos alunos da Faculdade Jardins, onde de verdade eu aprendi e me desenvolvi de uma forma profissional gigante. Só tenho a agradecer aos professores da Faculdade pelo aprendizado, ter participado das feiras, seminários e práticas empreendedoras. Tudo isso me fez ter outra mentalidade e visão para que eu me torne uma profissional muito mais completa.  Lembro da nossa Empresa Júnior, todo o empenho do professor Robson e dos demais professores que nos proporcionaram visitas e palestras importantes. Agradeço porque aprendi demais com toda a intensidade, energia e alegria de nosso coordenador.  Obrigada por todas as experiências que me fizeram passar e todas as vivências práticas de alta intensidade que vivi e vivo."
              image={depoimento4}
            />
            <Comment
              title="Darliene Tenório"
              description="Falar da Faculdade Jardins pra mim é recordar da minha oportunidade de estudos que tive em 2016.2 com baixo custo e alto padrão de qualidade. Falar dos professores em geral é dizer que não me arrependo em nada, pois tive muito aprendizado.  Eternamente grata pelas feiras expo negócios, participei de 04 delas, participei da Feira Cultural e diversas experiências práticas que me proporcionaram.  Nossa Empresa Júnior que o Prof. Robson fundou ainda em 2016 no meu primeiro período de Faculdade, foi maravilhosa a experiência, todas as visitas que fizemos ao longo dos anos, os aprendizados, práticas, tudo maravilhoso para compor meu currículo e hoje atual formanda, concluindo o oitavo período. Saio da Faculdade Jardins com um grande aprendizado e desenvolvimento prático.  Só tenho a agradecer ao nosso coordenador por se esforçar por nós alunos e pela Faculdade está sempre nos trazendo o melhor. Saio muito feliz e com a certeza que farei pós-graduação e darei continuidade aos meus estudos com a FacJardins."
              image={depoimento5}
            />
            <Comment
              title="Profª Mirianne Almeida"
              description="Escrever sobre a Faculdade Jardins é rememorar o ano de 2014, quando, na condição de Professora, eu disse sim ao Curso de Pedagogia e reafirmei o meu compromisso ético com a formação de Pedagogos e Pedagogas. Ao longo desse tempo, a sala de aula tem se tornado não só um ambiente privilegiado de formação acadêmica, mas, sobretudo, um lugar de sentir, pensar e aprender coletivamente – isto se deve a relação de parceria estabelecida com os nossos alunos e alunas. 
                  Como mulher negra, Pedagoga, Mestra e Doutora em Educação, ocupar este espaço é reafirmar a potência transformadora da educação e, cotidianamente, dizer sim as práticas pedagógicas que se tecem na sala de aula e humanizam o processo formativo dos nossos alunos e alunas."
              image={depoimento6}
            />
            <Comment
              title="Wilker Mecenas"
              description="A Faculdade Jardins está presente em nossos dias, somos gratos pela qualidade de ensino e pelo corpo de docentes excepcionais que nos fornecem a melhor qualidade para a aprendizagem na enfermagem."
              image={depoimento7}
            />
            <Comment
              title="Laura Fernandes"
              description="Meu nome é Laura Fernandes, iniciei meu curso na Faculdade jardins em 2018, sou acadêmica do Curso de Enfermagem da Faculdade Jardins. Sinto-me satisfeita e agraciada em ser aluna dessa Faculdade, pois temos  professores de excelência, com metodologia de ensino eficiente, que se preocupam em aprendermos de verdade o que é lecionado."
              image={depoimento8}
            />
            <Comment
              title="Prof João Mozart"
              description="A Faculdade Jardins vem potencializando os  processos de ensino aprendizagem para enfrentar os desafios de uma nova era, criando estratégias para, que seja possível, adentrar nas dinâmicas sociais   que se forma nos contextos atuais. Portanto , os saberes são  problematizados, revistados e produzidos com o intuito de contribuir com o refinamento   do campo educacional ,ao transversalizar  por questões sociais, políticas, econômicas e culturais. Assim, a Eequipe Jardins, faz parte do movimento que   observa  e que tem iniciativas para delinear  os contornos percorridos pelos profissionais da educação, no contexto de rearranjo do sistema capitalista, que remodelam novas reflexões, práticas e   teorias que compõem os saberes estabelecidos nas esferas da vida social. Por fim, fazer parte dessa instituição de ensino é proporcionar e vivenciar experiências intensas  de um grupo que  tem a sensibilidade de  interrogar  a natureza e o papel da Educação de qualidade, além de  colocar no coração da educação preocupações : éticas , estéticas  e políticas, insistindo também nas implicações  que elas exercem,nós atores sociais envolvidos que recebe a formação e naqueles que  conseguem ultrapassar os repertórios de saberes disponibilizados, ou seja,  descobre -se, nessas novas paisagens que se formam,  como a qualidade da educação afeta profundamente os atores sociais envolvidos  e tem a capacidade de alterar o clima social  que se apresenta em uma sociedade e que se conecta intensamente com os contextos sociais em que estão inseridos ."
              image={depoimento9}
            />
            <Comment
              title="Alexander Moreira"
              description="É uma empresa que valoriza os profissionais, dando oportunidades para todos mostrarem seu potencial e talentos. Sinto-me lisonjeado por ter a chance de colaborar com minhas experiências e conhecimentos para que a organização continue crescendo e obtendo sempre grandes resultados. 
                  Acredito também que a faculdade Jardins possui a missão de inserir no mercado de trabalho alunos capacitados e bem orientados com novas características, contribuindo para uma sociedade capacitada e preparada."
              image={depoimento10}
            />
            <Comment
              title="Záira Moura"
              description="Fazer parte da equipe de professores da Faculdade Jardins é ter a oportunidade e o privilégio de transformar sonhos em realidade. Aqui prezamos não apenas pela qualidade do ensino, mas também pelo desenvolvimento pessoal do aluno, tornando-o um profissional preparado para a vida e para o mercado de trabalho. Neste ambiente, o comprometimento profissional une-se aos laços de amizade, ultrapassando o ambiente da sala de aula. Estou feliz em colaborar com a equipe e tenho muito orgulho em fazer parte da Família Jardins. "
              image={depoimento11}
            />
            <Comment
              title="Liliam França"
              description="Sou Liliam França, aluna da faculdade Jardins, dos cursos de Bacharelado em Administração e Tecnólogo em Gestão de Recursos Humanos. Já, fiz outra graduação em outra instituição, porém presencial e nunca havia pensado em fazer uma graduação no sistema Ead. Mas fui apresentada a esse sistema e a Instituição Jardins, por amigas e professora que fazem parte da rede. A Faculdade me apresentou uma proposta excelente, diante da conjuntura que eu e muitos estamos  vivendo de desemprego e de pandemia mundial. Assim como,  está me proporcionando um ensino EAD de qualidade e o mais importante com preços baixíssimos de acordo com a minha realidade financeira. Por isso, que eu indico. Pude constatar que a Faculdade Jardins é excelência no faz, com ensino de qualidade  .
                  Obrigada Faculdade Jardins!"
              image={depoimento12}
            />
            <Comment title="Profª Eliane"
              description="Olá me chamo Eliane, sou professora graduada em pedagogia me despertou o interesse em fazer uma pós através de um colega de trabalho comecei a fazer a pós em Alfabetização e letramento é uma área em que gosto muito pretendo fazer outras pós pois estou gostando do método oferecido pela Faculdade Jardins quem ainda não fez aconselho fazer pois é uma Faculdade muito boa"
              image={depoimento13}
            />
            <Comment title="Betãnia Batista Ribeiro"
              description="Oi meu nome é Betãnia Batista Ribeiro, estou no 6 semestre do curso de pedagogia e estudar na faculdade jardins está sendo uma experiência muito boa, pois  me proporcionou momentos maravilhosos. Deixo aqui os meus parabéns á faculdade jardins, aos professores e todos que fazem parte dessa equipe. 
              Só tenho á agradecer: obrigada. "
              image={depoimento14}
            />
            <Comment title="Priscila"
              description="Meu nome é Priscila, estudo na Faculdade Jardins, faço a Graduação em Licenciatura em Pedagogia, optei em fazer nesta instituição por ter ótimos profissionais, ótimos professores, ótima estrutura, mensalidade acessível. Estou muito feliz em fazer a minha graduação nesta instituição. Super indico a todos em fazer uma graduação na Faculdade Jardins. "
              image={depoimento15}
            />
            <Comment title="Marizete Maria dos Santos de Moura"
              description="Me chamo Marizete Maria dos Santos de Moura,
               Sou graduada em Pedagogia e pós graduada em Psicologia Educacional, Letramento e Alfabetização e Gestão Escolar e Coordenação Pedagógica. No momento faço parte do grupo de colaboradores da Facjardins sendo coordenadora do polo na cidade de Umburanas-BA. É uma experiência riquíssima, bem como exercer minha profissão de diferentes maneiras; da sala de aula a coordenação. Ser pedagoga é assim, ser múltipla! "
              image={depoimento16}
            />
            <Comment title="Jessica Lima"
              description="Olá meu nome é Jessica Lima  
              Como está sendo a minha experiência? 
              Atualmente, estudo Tecnologia em Gestão de Recursos Humanos e Administração na modalidade EaD. O curso é perfeito e se adequa muito bem às minhas necessidades. 
              Estou sentindo falta de termos aula em aplicativos com zoo para que possa ocorrer interação de professores e alunos até entre os próprios alunos  
              Meu contato é diretamente com os professores, o que me permite uma rica troca de conhecimento e aprendizagem. As vídeoaulas são bem explicativas  
              O curso é bastante amplo e focado na prática profissional."
              image={depoimento17}
            />
          </OwlCarousel>
        </div>
      </Grid>
      {/* Testimonials END */}
      <Container style={{ paddingBottom: '50px' }}>
        <Grid container justifyContent="center" alignItems="flex-start">
          <Grid lg={12} container justifyContent="center" >
            <Box fontWeight={900} style={{ color: '#0b649e', fontWeight: '900', fontSize: '2.5rem', marginBottom: "70px", textAlign: "center" }}>
              Consulte aqui o cadastro das Instituições no Sistema e-MEC
            </Box>
          </Grid>
          <Grid lg={6} container alignItems="center" justifyContent="flex-start" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: "center" }}>
            <QrCodeImage img={qrCodeEmec} ieLogo={jardinsLogo} />
          </Grid>
          <Grid lg={6} container alignItems="center" justifyContent="flex-start" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: "center" }}>
            <QrCodeImage img={qrCodeEmec} ieLogo={jardinsAllLogo} />
          </Grid>
        </Grid>
      </Container>
      {/* About END */}


      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="popup-image-box">
          <img src={popupImg} alt="Quinta cultural" className="img-popup" />
        </Box>
      </Modal> */}
    </Layout >
  )
}

export default IndexPage
